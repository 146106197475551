


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { faBell, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import { toastError, toastSuccess } from "../../../errors.toast";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from "swiper";
import { Navigation } from "swiper";
import DateFormat from "../../../../../components/dateFormat";


const AlertsModal = (props) => {
    const activeAlerts = props?.activeAlerts;
    const inactiveAlerts = props?.inactiveAlerts;
    return (
        <Modal {...props}
            dialogClassName="modal-alets-width-containe"
            aria-labelledby="contained-modal-title-vcenter"
            
            centered >
            <Modal.Body>
                <Swiper
                    allowTouchMove={false}
                    scrollbar={{
                        hide: true,
                        draggable: false,
                        enabled: false
                    }}
                    slidesPerView={1}
                    initialSlide={1}
                    modules={[Scrollbar, Navigation]}
                    navigation={{
                        nextEl: '.form_menu_next',
                        prevEl: '.form_menu_prev',
                    }}
                >
                    <SwiperSlide>
                    <h5> &nbsp;Архів аварій</h5>
                        <div className="container p-1 alerts-container">
                            
                            
                            {inactiveAlerts.length === 0 ? <div class='d-flex align-items-center justify-content-center h-100'><div class='d-flex flex-column'>Список пустий.</div></div> : 
                             inactiveAlerts.map((item) => { 
                                    
                                return (<div class="alert alert-success mb-2 alert-dismissible alert-absolute fade show" key={item.id}>
                                <i class="fas fa-check me-2"></i>&nbsp;
                                <b>{item.name}</b>
                                <div dangerouslySetInnerHTML={{ __html: item.body }}/>
                                <div className="date">{<DateFormat date={item.date}  type={"news"}/>}</div>
                                
                                   
                                   
                                    </div>
                            )})}
                        </div>
                    </SwiperSlide>
                    <SwiperSlide >
                    <h5>&nbsp;Активні аварії</h5>
                        <div className="container p-1 alerts-container">
                        
                        
                            {activeAlerts.length === 0 ? <div class='d-flex align-items-center justify-content-center h-100'><div class='d-flex flex-column'>Аварійних ділянок не виявлено.</div></div> : 
                             activeAlerts.map((item) => { 
                                    
                                return (<div class="alert alert-danger mb-2 alert-dismissible alert-absolute fade show" key={item.id}>
                                <i class="fas fa-check me-2"></i>&nbsp;
                                <b>{item.name}</b>
                                <div dangerouslySetInnerHTML={{ __html: item.body }}/>
                                <div className="date">{<DateFormat date={item.date}  type={"news"}/>}</div>
                                
                                   
                                   
                                    </div>
                            )})}
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="d-flex justify-content-center form_menu">
                    <a className="form_menu_next link mr-4">Активні</a>
                    <a className="form_menu_prev link ml-4" >Архів аварій</a>
                </div>
            </Modal.Body>
        </Modal>
    );
}



const NotificationsComponent = (props) => {
    const NotificationsData = useSelector(state => state.nav.notificationsList);
    const loadNotificationsData = useSelector(state => state.nav);
    const alertsData = useSelector(state => state.nav.alerts);
    const [setNotificationshow, setNotification] = useState(false);
    const [modalAlertsShow, setAlertsModalShow] = useState(false);

    useEffect(() => {
        if (NotificationsData.length >= 1 && setNotificationshow && !loadNotificationsData.loading) {
            toastSuccess(`Нові сповіщення: ${NotificationsData.length}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadNotificationsData.loading]);
    const note = (name, type, body) => {
        const getType = (type) => {
            if (type === "Небезпека") {
                return "note-danger"
            }
            if (type === "Успіх") {
                return "note-success"
            }
            if (type === "Попередження") {
                return "note-warning"
            }
            else {
                return "note-info"
            }
        }
        return (<div key={Math.random()} className="note-container">
            <p className={`note ${getType(type)}`}>

                <strong>{name}</strong>
                <b dangerouslySetInnerHTML={{ __html: body }} />
            </p>
        </div>)
    }


    const notificationList = () => {
        if (props.notificationsList.length === 0) {
            return (<div className="noneList">Сповіщень немає</div>)
        }
        else {
            return NotificationsData.map(item => {
                return note(item.name, item.tags, item.body)


            }).slice(0, 5)
        }
    }
    window.onload = () => {
        if (typeof window.sessionStorage !== "undefined" && !sessionStorage.getItem('visited')) {
            sessionStorage.setItem('visited', true);
            setNotification(true)
        }
    }
    return <>
        <a className="nav-link">
            <NavDropdown
                id="nav-dropdown-notifications"
                drop="down"
                className="notificationContainer"
                title={
                    <a className="nav-link nav-icon iconClass">
                        <FontAwesomeIcon icon={faBell} />
                        {props.notifications === 0 ? null : <span className="badge badge-light">{props.notifications}</span>}
                    </a>
                }
            >
                <div className="notificationList">
                    {notificationList()}
                </div>

            </NavDropdown>
        </a>
        <a onClick={() => setAlertsModalShow(true)} className={`nav-link nav-icon iconClass ${alertsData > 0 ? "avIcon_red" : "avIcon"}`}>

            <FontAwesomeIcon icon={faExclamationTriangle} />
            {alertsData === 0 ? null : <span className="badge badge-light">{alertsData}</span>}
        </a>
        <AlertsModal
            show={modalAlertsShow}
            onHide={() => setAlertsModalShow(false)}
            activeAlerts={props.alertsData.active}
            inactiveAlerts={props.alertsData.inactive}
        />

    </>
}
export default NotificationsComponent;